<template>
  <div style="height: inherit">
    <b-row>
      <b-col>
        <b-card
          title="Profile"
        >
          <b-row>
            <b-col md="6">
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="UserIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t('First Name') }}</span>
                  </th>
                  <td class="pb-50">
                    {{ user.firstname }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="ChevronRightIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t('Last Name') }}</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ user.name }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="AtSignIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t('E-Mail') }}</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ user.mail }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="BarChart2Icon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t('SG Handicap') }}</span>
                  </th>
                  <td class="pb-50">
                    {{ user.handicap }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon
                      icon="PlayIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t('Status') }}</span>
                  </th>
                  <td class="pb-50">
                    {{ user.category }}
                  </td>
                </tr>

              </table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {}
  },
  computed: {
    user() {
      return getUserData()
    },
  },
  created() {
  },
  methods: {
  },
}
</script>

<style lang="scss">
</style>
